<template lang="html">
    <div class="container-fluid p-5">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        CB Point Transactions
                    </div>
                    <div class="card-body">
                        <div class="row mt-2">
                            <div class="col-md-12 m-2">
                                <div class="row search-form">
                                    
                                    <div class="col-md-3 mt-1">
                                        <input onfocus="(this.type='date')" placeholder="From Date" v-model="filter.fromDate" class="form-control" >
                                    </div>
                                    <div class="col-md-3 mt-1">
                                        <input onfocus="(this.type='date')" placeholder="To Date" v-model="filter.toDate" class="form-control" >
                                    </div>
                                    <div class="col-md-3 mt-1">
                                        <input type="text" v-model="filter.name" class="form-control" placeholder="Name">
                                    </div>
                                    <div class="col-md-3 mt-1">
                                        <input type="text" v-model="filter.phone" class="form-control" placeholder="Phone">
                                    </div>
                                    <div class="col-md-3 mt-1">
                                        <button class="btn btn-primary mr-1 " @click="search()"><i class="fas fa-search"></i> Search</button>
                                        <button class="btn btn-success" @click="pointExport()"><i class="fas fa-csv"></i> Export</button>
                                        <button class="btn btn-success" @click="pointExpireExport()"><i class="fas fa-csv"></i> Export (Expired)</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th v-for="(value, key) in columns"
                                                    v-bind:class="{ 'asc': filter.sort == key && filter.reverse == 'desc', 'desc': filter.sort == key && filter.reverse == 'asc' }">
                                                    <a href="#" @click="sortBy(key)" class="text-capitalize">
                                                        {{value}}
                                                    </a>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(data,index) in dataList">
                                                <td class="pt-4 text-capitalize" >{{data.name}}</td>
                                                <td class="pt-4">{{data.phone}}</td>
                                                <td class="pt-4">{{data.trans_id}}</td>
                                                <td class="pt-4">{{data.origin_amount}}</td>
                                                <td class="pt-4">{{data.expire_date}}</td>
                                                <td class="pt-4">{{data.date}}</td>
                                            </tr>
                                            <tr v-if="dataList.length <= 0 ">
                                                <td colspan="5" class="text-center">Empty List</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <nav v-if="dataList.length > 0 " class="table_info">
                                        Showing {{metaData.from}} to {{metaData.to}} of {{metaData.total}} entries
                                    </nav>
                                    <nav class="paging_simple_numbers">
                                        <ul class="pagination pagination-md">
                                            <li class="page-item"
                                                :class="{ 'disabled': metaData.prev_page_url === null }">
                                                <a href="#" class="page-link" @click="next(metaData.current_page-1)">
                                                    &laquo;
                                                </a>
                                            </li>
                                            <li class="page-item" v-for="page in metaData.last_page" :key="page"
                                                :class="{ 'active':metaData.current_page === page }"
                                                v-if="Math.abs(page - metaData.current_page) < 3 || page == metaData.total - 1 || page == 0">
                                                <a href="#" @click.prevent="next(page)" class="page-link">
                                                    {{ page }}
                                                </a>
                                            </li>
                                            <li class="page-item"
                                                :class="{ 'disabled': metaData.current_page === metaData.last_page }">
                                                <a href="#" class="page-link" @click="next(metaData.current_page+1)">
                                                    &raquo;
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import env from '../../enviorments'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'cbpointList',
        metaInfo: {
            title: "CB Point Transactions",
            titleTemplate: "%s ← HoHoDiDi Dashboard",
        },
        components: {
            Loading
        },
        data() {
            return {
                env,
                isLoading: false,
                fullPage: true,
                product: '',
                transfer: '',
                alert: "",
                id: '',
                permissions: "",
                dataList: [],
                columns: {
                    name: "User Name",
                    phone: "Phone",
                    trans_id: "Transaction Id",
                    point: "Point",
                    expire_date: "Expired Date",
                    date: "Date",
                },
                filter: {
                    page: 1,
                    reverse: "asc",
                    sort: "created_at",
                    fromDate: "",
                    toDate: "",
                    name: "",
                    phone: ""
                },
                metaData: {
                    last_page: null,
                    current_page: 1,
                    from: null,
                    to: null,
                    total: null,
                    prev_page_url: null
                }
            }
        },
        watch: {
        '$route' (to, from) {
            this.fetchData()
        }
    },
        computed: {},
        methods: {
            ...mapActions({
                cbpointListAction: 'cbpointListAction',
                pointExportAction: 'pointExportAction',
                pointExpireExportAction: 'pointExpireExportAction'
            }),
            sortBy(column) {
                this.filter.sort = column
                if (this.filter.sort == column) {
                    if (this.filter.reverse == "asc") {
                        this.filter.reverse = "desc"
                    } else if (this.filter.reverse == "desc") {
                        this.filter.reverse = "asc"
                    }
                } else {
                    this.filter.reverse = "asc"
                }
                this.fetchData()
            },
            async search() {
                this.filter.page = 1
                this.fetchData()
            },
            async pointExport() {
                this.filter.page = 1
   
                this.isLoading = true
                let option = this.filter
                await this.pointExportAction({
                    ...option
                }).then(res => {
                    if (res.status == "success") {
                        const url = env.mediaUrl+"/"+res.data.data.downlodPath;
                        console.log(url)
                        window.location.href = url;
                        this.isLoading = false;
                    }
                }).catch(err => this.isLoading = true)
            },
            async pointExpireExport() {
                this.filter.page = 1
   
                this.isLoading = true
                let option = this.filter
                await this.pointExpireExportAction({
                    ...option
                }).then(res => {
                    if (res.status == "success") {
                        const url = env.mediaUrl+"/"+res.data.data.downlodPath;
                        console.log(url)
                        window.location.href = url;
                        this.isLoading = false;
                    }
                }).catch(err => this.isLoading = true)
            },
            async fetchData() {
                this.isLoading = true
                let option = this.filter
                await this.cbpointListAction({
                    ...option
                }).then(res => {
                    if (res.status == "success") {
                        this.dataList = res.data.data
                        this.product = res.data.meta.product
                        this.transfer = res.data.meta.transfer
                        this.metaData.last_page = res.data.meta.last_page
                        this.metaData.current_page = res.data.meta.current_page
                        this.metaData.prev_page_url = res.data.links.prev
                        this.metaData.from = res.data.meta.from
                        this.metaData.to = res.data.meta.to
                        this.metaData.total = res.data.meta.total
                        this.$router.replace({ path: 'cb-point-list', query: { page: this.filter.page, reverse: this.filter.reverse, sort: this.filter.sort} }).catch(()=>{})
                        this.isLoading = false;
                    }
                }).catch(err => this.isLoading = true)
            },
            next: function (page) {
                this.filter.page = page
                this.fetchData();
            },
            reset(){
                this.$router.replace({ path: 'cb-point-list', query: {page: 1, reverse: 'desc', sort: 'created_at'} }).catch(()=>{})
                 this.$router.go()
            },
            editData(data) {
                this.$router.replace({ path: 'point-edit', query: { page: this.filter.page, reverse: this.filter.reverse, sort: this.filter.sort, id: data.id } }).catch(()=>{})
            },
            deleteData(data){
                this.id = data.id
            },
            async deleteItem() {
				this.isLoading = true
				let option = {
					id: this.id,
				}
				await this.pointDeleteAction({
					...option
				}).then(res => {
					this.fetchData()
                    this.$refs['my-modal'].hide()
                    this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
            checkPermission: function (key) {
                if(key in this.permissions){
                    return true
                }else{
                    return false
                }
            }

        },
        mounted() {
            let user = JSON.parse(window.localStorage.getItem('user'))
            this.permissions = user.permissions
            this.filter.page = this.$route.query.page
            this.filter.reverse = this.$route.query.reverse
            this.filter.sort = this.$route.query.sort
            this.fetchData()
        }
    }
</script>

<style scoped lang="scss">
    .action {
        border: none;
        background: none;
        font-size: 24px;
    }
</style>